import { Avatar } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { formatDate, getType } from '../helpers'
import { useTranslation } from 'react-i18next'
import { useReactToPrint } from 'react-to-print'

export default function VisitorCard({
  type,
  fullName,
  companyName,
  contactPerson,
  profilePhoto,
  checkInStartDate,
  checkInEndDate,
}) {
  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })

  const now = new Date()

  const getDate = () => {
    let date
    if (checkInStartDate) date = formatDate(checkInStartDate)
    if (checkInEndDate) date += ' - ' + formatDate(checkInEndDate)
    if (!checkInStartDate) date = formatDate(now)
    return date
  }

  const { t } = useTranslation()

  useEffect(() => {
    handlePrint()
  }, [])

  return (
    <div className='hidden' id='visitCard'>
      <div
        ref={componentRef}
        className='flex flex-col justify-center items-center px-4 h-full w-full mx-auto'
        id='print'
      >
        {/* Front Page Content */}
        <div className='page-front w-1/2'>
          <div className='border-darkGray border-8 min-h-52'>
            <div className='bg-white flex flex-row justify-between items-center border-b-8 border-red'>
              <div className='mx-5'>
                <h1 className='font-diodrumMedium text-2xl text-neutral-800'>
                  {getType(type, t)}
                </h1>
              </div>
              <div className='m-2'>
                <img
                  src='/images/dupont-logo.png'
                  alt='Dupont logo'
                  loading='lazy'
                  className='w-28 h-10'
                />
              </div>
            </div>

            <div className='bg-white'>
              <div className='flex flex-row justify-between'>
                <div className='flex flex-col justify-between mx-5 my-2'>
                  <div>
                    <p className='text-xs text-neutral-600 font-capitalize'>
                      {t('first name') + ' ' + t('last name')}
                    </p>
                    <h1 className='font-diodrumMedium text-2xl text-neutral-800'>
                      {fullName}
                    </h1>
                  </div>
                  {companyName ? (
                    <div>
                      <p className=' text-xs text-neutral-600 font-diodrumMedium'>
                        {t('company')}
                      </p>
                      <h1 className='font-diodrumMedium text-2xl text-neutral-800'>
                        {companyName}
                      </h1>
                    </div>
                  ) : (
                    ''
                  )}
                  {contactPerson ? (
                    <div>
                      <p className=' text-xs text-neutral-600 font-diodrumMedium'>
                        {t('contact person')}
                      </p>
                      <h1 className='font-diodrumMedium text-2xl text-neutral-800'>
                        {contactPerson}
                      </h1>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
                <div className='p-3'>
                  {profilePhoto !== null ? (
                    <img
                      id='profile-image-table'
                      src={profilePhoto}
                      alt='Profile pic'
                      loading='lazy'
                      className='h-40 w-32 cover mx-5 mt-5'
                    />
                  ) : (
                    <Avatar
                      sx={{
                        background: 'white',
                        width: 110,
                        height: 130,
                        borderRadius: 0,
                        marginTop: 2,
                        marginLeft: 2,
                        marginRight: 2,
                        color: '#a8a29e',
                      }}
                      aria-label='recipe'
                    />
                  )}
                  <div className='flex justify-end'>
                    <p className='justify-right px-5'>{getDate()}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Back Page Content */}
        <div className='bg-white border-darkGray border-8 page-back my-auto h-full mx-auto w-1/2'>
          <div className='flex justify-center border-b-8 border-red p-2 w-full'>
            <img
              src='/images/dupont-logo.png'
              alt='Dupont Logo'
              loading='lazy'
              className='w-28 h-10'
            />
          </div>

          <div className='flex flex-row items-center justify-center h-48'>
            <div className='mx-2 px-2 text-center'>
              <h4 className='text-md text-neutral-600'>
                Bei Fragen oder Problemen, melden. sie sich gerne bei unserer
                Rezeption:
              </h4>
              <h4 className='text-md'>Tel. +49 6102 18-1028</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
