import React, { useState } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import isEmail from 'validator/lib/isEmail'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import Checkbox from '@mui/material/Checkbox'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import TextFieldWithTitle from './form-components/text-field-with-title'
import SelectField from './form-components/select-field'
import CheckboxField from './form-components/checkbox-field'

import DateField from './form-components/date-field'
import RoundedComponent from '../cards/rounded'
import api from '../../api'
import { setState } from '../../hooks/state'
import { formatDateWithTime } from '../../helpers'
import Loader from '../../components/loader'
import VisitorCard from '../visitor-card'

const PreCheckinForm = () => {
  const [checkinPeriodOpened, setCheckinPeriodOpened] = useState(false)
  const [longTermOpened, setLongTermOpened] = useState(false)
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState('')
  const [loading, setLoading] = useState(false)
  const [workerFromThisSide, setWorkerFromThisSide] = useState(null)
  const [showVisitorCard, setShowVisitorCard] = useState(false)
  

  const { t, i18n } = useTranslation()

  const navigate = useNavigate()

  const options = [
    {
      label: t('visitor'),
      value: 'visitor',
    },
    {
      label: t('worker'),
      value: 'worker',
    },
    {
      label: t('external company'),
      value: 'external_company',
    },
  ]

  const addVisitor = async (values) => {
    setLoading(true)

    const checkInStartDate =
      values.longDate !== null
        ? formatDateWithTime(
            values.longDate.startDate,
            values.time.split(':')[0],
            values.time.split(':')[1]
          )
        : formatDateWithTime(
            values.oneDayDate,
            values.time.split(':')[0],
            values.time.split(':')[1]
          )

    const checkInEndDate =
      values?.longDate?.endDate !== null
        ? formatDateWithTime(values?.longDate?.endDate, 23, 59, 59)
        : null

    let data = {
      fullName: values.name + ' ' + values.surname,
      email: values.email,
      type: values.position,
      companyName: values.companyName,
      plateNumber: values.licensePlateNumber,
      contactPerson: values.contactPerson,
      telNumber: values.telNumber,
      checkInStartDate: checkInStartDate,
      language: i18n.language,
      disableSecurityCheck: values.disableSecurityCheck,
      workerFromThisSide
    }

    if (values?.visitCardNumber) {
      data = {
        ...data,
        visitorCardId: values.visitCardNumber,
      }
    }

    if (values?.longDate) {
      data = {
        ...data,
        checkInEndDate: checkInEndDate,
      }
    }

    await api
      .post('visitor', data)
      .then(async (response) => {
        if (response.data) {
          setState({ newVisitor: response.data })
          setMessage('description.visitor-successsful')
          setStatus('success')
          setTimeout(() => {
            setLoading(false)
            navigate('/confirmation')
          }, 3000)
        }
      })
      .catch((err) => {
        console.log('err', err)
        if (err && err.response.data.code === 'already_exists') {
          setMessage('visitor-exists')
        } else setMessage('description.visitor-error')
        setStatus('error')
        setLoading(false)
      })
  }
  return (
    <div className={'flex flex-col'}>
      <Formik
        initialValues={{
          position: 'visitor',
          name: '',
          surname: '',
          email: null,
          companyName: '',
          licensePlateNumber: '',
          contactPerson: '',
          telNumber: '',
          time: '',
          accessVisitCardNumber: false,
          visitCardNumber: '',
          oneDayDate: '',
          longDate: null,
          rememberMe: false,
          disableSecurityCheck: false,
        }}
        onSubmit={(values) => {
          addVisitor(values)
          setShowVisitorCard(true)
        }}
        validationSchema={Yup.object().shape({
          position: Yup.string().required('postion required'),
          name: Yup.string().min(2, 'min 2 chars').required('mandatory field'),
          surname: Yup.string()
            .min(2, 'min 2 chars')
            .required('mandatory field'),
          email: Yup.string()
            .email('invalid email')
            .test((value) => (value ? isEmail(value) : true))
            .nullable(),
          companyName: Yup.string()
            .min(2, 'min 2 chars')
            .when('position', {
              is: (position) => position === 'external_company',
              then: () =>
                Yup.string().min(2, 'min 2 chars').required('mandatory field'),
              otherwise: (schema) => schema.notRequired(),
            }),
          licensePlateNumber: Yup.string().min(2, 'min 2 chars'),
          contactPerson: Yup.string()
            .min(2, 'min 2 chars')
            .when([], {
              is: () => !workerFromThisSide,
              then: () => Yup.string().required('mandatory field'),
              otherwise: (schema) => schema.notRequired(),
            }),
          telNumber: Yup.string()
            .matches(/^\+?[0-9]*$/, 'invalid phone number')
            .when('position', {
              is: (position) => position === 'external_company',
              then: () => Yup.string().required('mandatory field'),
              otherwise: (schema) => schema.notRequired(),
            }),
          time: Yup.string().required('mandatory field'),
          accessVisitCardNumber: Yup.bool(),
          visitCardNumber: Yup.string().when('accessVisitCardNumber', {
            is: true,
            then: () =>
              Yup.string()
                .required(t('mandatory field'))
                .matches(/^\d+$/, t('card number validation')), // Only digits
            otherwise: (schema) => schema.notRequired(),
          }),
          oneDayDate: Yup.mixed().nullable().required('mandatory field'),
          longDate: Yup.mixed().nullable(),
        })}
      >
        {({ errors, values, touched, handleChange, handleSubmit }) => {
          const handleChangePosition = (newValue) => {
            if (newValue !== 'worker') {
              setWorkerFromThisSide(null)
            }
          }

          const openWorkerSidePopUp =  values.position === 'worker' && workerFromThisSide === null


          if (openWorkerSidePopUp) {
            document.body.classList.add('overflow-hidden')
          } else {
            document.body.classList.remove('overflow-hidden')
          }

          return (
            <form
              onSubmit={handleSubmit}
              className='relative pl-1.5 ml-14 -mr-14'
              style={
                checkinPeriodOpened || longTermOpened
                  ? { minHeight: '122vh' }
                  : {}
              }
            >
              <div className='grid grid-rows-1'>
                <div className='relative top-0 '>
                  {(checkinPeriodOpened || longTermOpened) && (
                    <div className='absolute top-0 bottom-0 right-0 -left-20 z-10 bg-lightGray bg-opacity-60' />
                  )}
                  <div className='w-1/2'>
                    <div className='flex justify-start mb-5 col-span-2 -ml-8'>
                      <RoundedComponent renderNumb='1' />

                      <p className='text-sm text-black ml-3 uppercase'>
                        {t('personal data')}
                      </p>
                    </div>
                    <div className='grid grid-cols-2 gap-y-0 gap-x-2 w-full '>
                      <SelectField
                        uppername='Position'
                        name={'position'}
                        placeholder={'Selected Position'}
                        value={values.position}
                        className='w-full flex flex-col relative justify-center h-14 bg-white border-transparent text-sm text-zinc-400 shadow-lg rounded-sm'
                        error={touched.position && Boolean(errors.position)}
                        helperText={touched.position && errors.position}
                        errorMessage={errors.position}
                        options={options}
                        required={!workerFromThisSide}
                        onChange={handleChangePosition}
                      />
                    </div>
                    <div className='grid grid-cols-2 gap-y-0 gap-x-2 w-full capitalize'>
                      <TextFieldWithTitle
                        uppername={t('first name')}
                        placeholder={'Max'}
                        type='text'
                        name='name'
                        value={values.name}
                        className='w-full flex flex-col relative justify-center h-14 bg-white border-transparent text-sm pl-3 text-zinc-400 shadow-lg rounded-sm'
                        onChange={(e) => {
                          handleChange({
                            target: {
                              name: 'name',
                              value: e.target.value.replace(/[^A-Za-z\s]/g, ''),
                            },
                          })
                        }}
                        required
                        error={touched.name && Boolean(errors.name)}
                        helperText={touched.name && errors.name}
                        errorMessage={errors.name}
                      />
                      <TextFieldWithTitle
                        uppername={t('last name')}
                        placeholder={'Mustermann'}
                        type='text'
                        name='surname'
                        value={values.surname}
                        className='w-full flex flex-col relative justify-center h-14 bg-white border-transparent text-sm pl-3 text-zinc-400 shadow-lg rounded-sm'
                        onChange={(e) => {
                          handleChange({
                            target: {
                              name: 'surname',
                              value: e.target.value.replace(/[^A-Za-z\s]/g, ''),
                            },
                          })
                        }}
                        required
                        error={touched.surname && Boolean(errors.surname)}
                        helperText={touched.surname && errors.surname}
                        errorMessage={errors.surname}
                      />
                    </div>
                    <div className='grid grid-cols-1 gap-1 w-full '>
                      <TextFieldWithTitle
                        uppername='E-Mail'
                        placeholder={'max@mustermann.de'}
                        type='text'
                        name='email'
                        value={values.email}
                        className='w-full flex flex-col relative  justify-center h-14 bg-white border-transparent text-sm pl-3 text-zinc-400 shadow-lg rounded-sm'
                        onChange={handleChange}
                        error={touched.email && Boolean(errors.email)}
                        helperText={touched.email && errors.email}
                        errorMessage={errors.email}
                      />
                    </div>
                    <div className='grid grid-cols-2 gap-y-0 gap-x-2 w-full capitalize'>
                      <TextFieldWithTitle
                        uppername={t('company name')}
                        placeholder={t('company name')}
                        type='text'
                        name='companyName'
                        value={values.companyName}
                        className='w-full flex flex-col relative justify-center h-14 bg-white border-transparent text-sm pl-3 text-zinc-400 shadow-lg rounded-sm'
                        onChange={handleChange}
                        required={values.position === 'external_company'}
                        error={
                          touched.companyName && Boolean(errors.companyName)
                        }
                        helperText={touched.companyName && errors.companyName}
                        errorMessage={errors.companyName}
                      />
                      <TextFieldWithTitle
                        uppername={t('plate number')}
                        placeholder={'GN-IU22'}
                        type='text'
                        name='licensePlateNumber'
                        value={values.licensePlateNumber}
                        className='w-full flex flex-col relative justify-center h-14 bg-white border-transparent text-sm pl-3 text-zinc-400 shadow-lg rounded-sm'
                        onChange={(e) => {
                          handleChange({
                            target: {
                              name: 'licensePlateNumber',
                              value: e.target.value.replace(
                                /[^0-9a-zA-Z\s-]+/g,
                                ''
                              ),
                            },
                          })
                        }}
                        error={
                          touched.licensePlateNumber &&
                          Boolean(errors.licensePlateNumber)
                        }
                        helperText={
                          touched.licensePlateNumber &&
                          errors.licensePlateNumber
                        }
                        errorMessage={errors.licensePlateNumber}
                      />
                    </div>
                    <div className='grid grid-cols-2 gap-y-0 gap-x-2 w-full capitalize'>
                      <TextFieldWithTitle
                        uppername={t('contact person')}
                        placeholder={t('firstAndLastname')}
                        type='text'
                        name='contactPerson'
                        value={values.contactPerson}
                        className='w-full flex flex-col relative  justify-center h-14 bg-white border-transparent text-sm pl-3 text-zinc-400 shadow-lg rounded-sm'
                        onChange={(e) => {
                          handleChange({
                            target: {
                              name: 'contactPerson',
                              value: e.target.value.replace(/[^A-Za-z\s]/g, ''),
                            },
                          })
                        }}
                        required={!workerFromThisSide}
                        error={
                          touched.contactPerson && Boolean(errors.contactPerson)
                        }
                        helperText={
                          touched.contactPerson && errors.contactPerson
                        }
                        errorMessage={errors.contactPerson}
                      />

                      <TextFieldWithTitle
                        uppername={t('phone')}
                        placeholder={t('phone')}
                        type='tel'
                        name='telNumber'
                        value={values.telNumber}
                        className='w-full flex flex-col relative  justify-center h-14 bg-white border-transparent text-sm pl-3 text-zinc-400 shadow-lg rounded-sm'
                        onChange={(e) => {
                          handleChange({
                            target: {
                              name: 'telNumber',
                              value: e.target.value.replace(/[^0-9+]/g, ''),
                            },
                          })
                        }}
                        required={values.position === 'external_company'}
                        error={touched.telNumber && Boolean(errors.telNumber)}
                        helperText={touched.telNumber && errors.telNumber}
                        errorMessage={errors.telNumber}
                      />
                    </div>
                  </div>
                </div>

                <div
                  className='w-1/2'
                  style={longTermOpened ? { paddingBottom: '9rem' } : {}}
                >
                  <div className='flex justify-start flex-col mb-5 '>
                    <div className='flex flex-start mb-3 -ml-8'>
                      <RoundedComponent renderNumb='2' />
                      <p className='text-sm text-black ml-3 uppercase'>
                        {t('checkin information')}
                      </p>

                      <div className='flex justify-center items-center ml-2'>
                        <img
                          src='/images/icons/calendar-icon.png'
                          alt='image'
                          loading='lazy'
                          className='w-4 h-4  '
                        />
                      </div>
                    </div>

                    <div className='grid grid-cols-2 gap-y-0 gap-x-2 w-full relative capitalize'>
                      <DateField
                        dateType='date-picker'
                        uppername={t('check in period')}
                        placeholder={t('choose')}
                        name='oneDayDate'
                        date={values.oneDayDate}
                        touched={touched}
                        required
                        errors={errors}
                        disablePrevievDates={true}
                        disabled={values.longDate?.startDate || false}
                        tooltip={t('checkin period disabled message')}
                        // position={'right-60 bottom-auto top-32 pb-20'}
                        itemsAligned='end'
                        calendarOpened={checkinPeriodOpened}
                        setCalendarOpened={setCheckinPeriodOpened}
                      />

                      <TextFieldWithTitle
                        uppername={t('time')}
                        placeholder={'00:00'}
                        type='time'
                        name='time'
                        value={values.time || ''}
                        className={`w-full flex flex-col relative justify-center h-14 bg-white border-transparent text-sm pl-3  shadow-lg rounded-sm ${
                          values.time !== '' ? 'text-zinc-400' : 'text-textGray'
                        }`}
                        onChange={handleChange}
                        required
                        error={touched.time && Boolean(errors.time)}
                        helperText={touched.time && errors.time}
                        errorMessage={errors.time}
                      />
                    </div>

                    <div className='-ml-1 mb-2'>
                      <Checkbox
                        name='accessVisitCardNumber'
                        checked={values.accessVisitCardNumber}
                        onChange={handleChange}
                        sx={{ padding: 0, marginRight: '4px' }}
                      />
                      <span>{t('visitor card issued')}? </span>
                    </div>

                    <div className='grid grid-cols-2 gap-y-0 gap-x-2 w-full relative'>
                      <TextFieldWithTitle
                        uppername={t('visit card number')}
                        placeholder={'2131242142'}
                        type='text'
                        name='visitCardNumber'
                        value={values.visitCardNumber}
                        className='w-full flex flex-col relative justify-center capitalize h-14 bg-white border-transparent text-sm pl-3 text-zinc-400 shadow-lg rounded-sm'
                        onChange={(e) => {
                          handleChange({
                            target: {
                              name: 'visitCardNumber',
                              value: e.target.value.replace(/[^0-9]/g, ''),
                            },
                          })
                        }}
                        disabled={!values.accessVisitCardNumber}
                        required={
                          values.accessVisitCardNumber === true ? true : false
                        }
                        error={
                          touched.visitCardNumber &&
                          Boolean(errors.visitCardNumber)
                        }
                        helperText={
                          touched.visitCardNumber && errors.visitCardNumber
                        }
                        errorMessage={errors.visitCardNumber}
                      />
                      <DateField
                        dateType='date-range-picker'
                        uppername={t('create a long-term check-in')}
                        placeholder='MM / YY'
                        name='longDate'
                        date={values.longDate}
                        touched={touched}
                        errors={errors}
                        disablePrevievDates={true}
                        itemsAligned='end'
                        calendarOpened={longTermOpened}
                        setCalendarOpened={setLongTermOpened}
                      />

                    </div>
                    <div className='grid grid-cols-2 gap-y-0 gap-x-2 w-full relative mb-5'>
                      <CheckboxField name='disableSecurityCheck' label={t('disable security check')} />
                    </div>
                    <div
                      className={`flex justify-start flex-col mb-5 relative ${
                        loading ? 'bg-green  rounded-lg' : ''
                      }`}
                    >
                      <button
                        type='submit'
                        value='rtl'
                        disabled={loading}
                        className='bg-green rounded-lg w-full h-12 text-sm text-white'
                      >
                        {loading ? (
                          <Loader text={t('loading') + '...'} />
                        ) : (
                          <>
                            <CheckCircleRoundedIcon
                              fontSize='large'
                              sx={{
                                color: '#ffffff',
                                marginRight: '10px',
                                padding: '3px',
                              }}
                            />
                            {t('pre-check-in')}
                          </>
                        )}
                      </button>
                    </div>
                    {status && message && (
                      <p
                        className={`${
                          status === 'success' ? 'text-green' : 'text-red'
                        }`}
                      >
                        {t(message)}
                      </p>
                    )}
                  </div>
                </div>
              </div>

              { openWorkerSidePopUp  && (
                <div className='fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-20 flex items-center justify-center'>
                  <div className='p-4 bg-white rounded-lg'>
                    <h2 className='text-lg font-semibold mb-4'>
                      {t('workerSideQuestion')}
                    </h2>
                    <div>
                      <label className='flex items-center gap-2'>
                        <input
                          type='checkbox'
                          onChange={() => setWorkerFromThisSide(true)}
                        />
                        {t('workerFromThisSide')}
                      </label>
                      <label className='flex items-center gap-2'>
                        <input
                          type='checkbox'
                          onChange={() => setWorkerFromThisSide(false)}
                        />
                        {t('workerFromAnotherSide')}
                      </label>
                    </div>
                  </div>
                </div>
              )}
              {showVisitorCard && (
                <VisitorCard
                  type={values.type}
                  fullName={values.name + ' ' + values.surname}
                  companyName={values.companyName}
                  contactPerson={values.contactPerson}
                  profilePhoto={values.profilePhoto}
                  checkInStartDate={
                    values.dateRange?.startDate || values.checkInStartDate
                  }
                  checkInEndDate={
                    values.dateRange?.endDate || values.checkInEndDate
                  }
                  checkOutDate={values.checkOutDate}
                  setShowVisitorCard={setShowVisitorCard}
                />
              )}
            </form>
          )
        }}
      </Formik>
    </div>
  )
}
export default PreCheckinForm
