import { Checkbox, FormControlLabel } from "@mui/material";
import { Field } from "formik";

const CheckboxField = ({ name, label, disabled }) => {
  return (
    <Field name={name} type="checkbox">
      {({ field }) => {
        return (
          <FormControlLabel
            onChange={field.onChange}
            sx={{ marginLeft: "0px" }}
            control={
              <Checkbox
                checked={field.checked}
                name={name}
                disabled={disabled}
                sx={{ padding: 0, marginRight: "4px" }}
              />
            }
            label={label}
          />
        );
      }}
    </Field>
  );
};

export default CheckboxField;
