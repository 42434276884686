import React, { useState, useEffect } from 'react'
import TextFieldWithTitle from './text-field-with-title'
import { useFormikContext } from 'formik'
import { DatePicker } from '../../calendar'
import { formatDate } from '../../../helpers'
import usePopupVisible from '../../../hooks/usePopupVisible'

const DateField = ({
  uppername,
  placeholder,
  name,
  date,
  touched,
  errors,
  disabled,
  tooltip,
  dateType,
  calendarOpened,
  setCalendarOpened,
  itemsAligned,
  disablePrevievDates,
  required,
}) => {
  const [openCalendar, setOpenCalendar] = useState(false)
  const formikProps = useFormikContext()
  const { ref, isPopupVisible, setIsPopupVisible } = usePopupVisible(false)

  const handleCalendarOpen = () => {
    setOpenCalendar(!openCalendar)
    setCalendarOpened(!calendarOpened)
  }

  useEffect(() => {
    setIsPopupVisible(openCalendar)
  }, [openCalendar])

  useEffect(() => {
    !isPopupVisible && setOpenCalendar(false)
    !isPopupVisible && setCalendarOpened(false)
  }, [isPopupVisible])

  const getDateValue = () => {
    if (date) {
      if (dateType === 'date-picker') return formatDate(date)
      return formatDate(date?.startDate) + '-' + formatDate(date?.endDate)
    }
    return null
  }
  return (
    <div className='date-wrapper'>
      <TextFieldWithTitle
        onClick={handleCalendarOpen}
        uppername={uppername}
        placeholder={placeholder}
        type='text'
        name={name}
        value={getDateValue() || placeholder}
        disabled={disabled}
        tooltip={disabled && tooltip}
        className={`w-full flex flex-col relative justify-center h-14 bg-white border-transparent text-sm pl-3 text-zinc-400 shadow-lg rounded-sm text-[15px] ${
          disabled ? 'opacity-60' : ''
        }`}
        required={required}
        error={touched[name] && Boolean(errors[name])}
        helperText={touched[name] && errors[name]}
        errorMessage={errors[name]}
      />
      {openCalendar && (
        <div ref={ref} className='absolute z-10 top-16'>
          <DatePicker
            type={dateType}
            dateRange={date}
            disablePrevievDates={disablePrevievDates}
            setDateRange={(value) => {
              formikProps.setFieldValue(`${name}`, value)
              if (name === 'longDate' && value.startDate) {
                formikProps.setFieldValue(`oneDayDate`, value.startDate)
                if (formatDate(value.startDate) === formatDate(value.endDate)) {
                  formikProps.setFieldValue(`${name}`, null)
                  formikProps.setFieldValue(`oneDayDate`, null)
                }
              }
            }}
            setOpen={handleCalendarOpen}
            open={openCalendar}
          />
        </div>
      )}
    </div>
  )
}

export default DateField
