import React, { useState, useCallback, useEffect } from 'react'
import Card from '@mui/material/Card'
import { Avatar } from '@mui/material'
import CardContent from '@mui/material/CardContent'
import Checkbox from '@mui/material/Checkbox'
import CancelIcon from '@mui/icons-material/Cancel'
import { CheckCircleRounded, QrCode } from '@mui/icons-material'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { DatePicker } from '../calendar'
import * as QRCode from 'qrcode'
import {
  formatDate,
  formatTime,
  getType,
  showVisitorData,
  getStatusLabelOnModal,
} from '../../helpers'
import CalendarIcon from '../../icons/calendar-icon'
import api from '../../api'
import VisitorCard from '../visitor-card'
import { useTranslation } from 'react-i18next'
import axios from 'axios'

export default function CheckInsCard({
  currentCheckin,
  setCardOpen,
  dataType,
  getData,
  page,
  table,
}) {
  const {
    status,
    visitSectionId,
    visitorCardId,
    type,
    fullName,
    email,
    companyName,
    contactPerson,
    plateNumber,
    profilePhoto,
    checkInStartDate,
    checkInEndDate,
    checkOutDate,
    telNumber,
    disableSecurityCheck
  } = currentCheckin
  const [dateRange, setDateRange] = useState(null)
  const startDate = formatDate(dateRange?.startDate)
  const endDate = formatDate(dateRange?.endDate)
  const [showVisitorCard, setShowVisitorCard] = useState(false)
  const [isCalendarOpen, setCalendarOpen] = useState(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [initialValues, setInitialValues] = useState({
    cardNumber: visitorCardId || '',
    cardHandedOut: false,
  })
  const [visitorDocName, setVisitorDocName] = useState('')
  const [contractorDocName, setContractorDocName] = useState('')
  const [error, setError] = useState('')
  const { t, i18n } = useTranslation()

  const [pdfFiles, setPdfFiles] = useState('')

  const handleCalendar = () => {
    setCalendarOpen(!isCalendarOpen)
  }

  const getTitle = (status) => {
    if (status === 'draft_check_in' || status === 'check_in') return 'Check-In'
    if (status === 'pre_check_in') return t('pre check in')
    if (status === 'check_out') return 'Check-Out'
    return
  }
  const getButtonStyle = (status) => {
    if (status === 'draft_check_in')
      return { bgColor: 'bg-green', label: t('confirm') }
    if (status === 'pre_check_in')
      return { bgColor: 'bg-buttonGray', label: t('pre check in') }
    if (status === 'check_in')
      return { bgColor: 'bg-red', label: t('check out') }
    return { bgColor: 'bg-darkestBlue', label: t('checked out') }
  }

  const {
    safetyPathVisitorEn,
    safetyPathContractorEn,
    safetyPathVisitorDe,
    safetyPathContractorDe,
  } = pdfFiles

  const safetyPathVisitor =
    i18n.language === 'en' ? safetyPathVisitorEn : safetyPathVisitorDe
  const safetyPathContractor =
    i18n.language === 'en' ? safetyPathContractorEn : safetyPathContractorDe
  const getSafetyDocs = () => {
    if (type === 'visitor')
      return { name: visitorDocName, link: safetyPathVisitor }
    if (type === 'external_company')
      return {
        name: contractorDocName,
        link: safetyPathContractor,
      }
  }

  const newStatus = status === 'draft_check_in' ? 'check_in' : 'check_out'

  const handleUpdateStatus = async (values) => {
    const data = {
      status: newStatus,
      cardPrintedOut: values.cardHandedOut,
      visitorCardId: values.cardNumber,
      checkInStartDate: dateRange?.startDate || checkInStartDate,
      checkInEndDate: dateRange?.endDate || checkInEndDate,
    }

    await api
      .patch(`visitor/${visitSectionId}`, data)
      .then(async (response) => {
        getData(page)
        setCardOpen(false)
        return true
      })
      .catch((err) => {
        if (
          err.response.data.message === 'Visitor Card Issued must be checked!'
        ) {
          setError('cardError')
        }
        return false
      })
  }
  const getVisitor = useCallback(async () => {
    await api.get(`visitor/${visitSectionId}`).then((res) => {
      setInitialValues({
        ...initialValues,
        cardHandedOut: res.data.cardPrintedOut,
      })
    })
  }, [visitSectionId])

  const deleteVisitor = async () => {
    await api
      .delete(`visitor-section/${visitSectionId}`)
      .then(async (response) => {
        if (response.data) {
          getData(page)
          setCardOpen(false)
          setDeleteDialogOpen(false)
        }
      })
      .catch((err) => console.log('err', err))
  }

  const getSettingsPdf = useCallback(async () => {
    await api
      .get(`/settings/pdf-url`)
      .then((res) => {
        setPdfFiles(res.data)
      })
      .catch((err) => err)
  }, [])

  useEffect(() => {
    if (i18n.language === 'en') setVisitorDocName(pdfFiles?.safetyNameVisitorEn)
    if (i18n.language === 'de') setVisitorDocName(pdfFiles?.safetyNameVisitorDe)
    if (i18n.language === 'en')
      setContractorDocName(pdfFiles?.safetyNameContractorEn)
    if (i18n.language === 'de')
      setContractorDocName(pdfFiles?.safetyNameContractorDe)
  }, [pdfFiles, i18n.language])

  useEffect(() => {
    getVisitor()
    getSettingsPdf()
  }, [])

  if (showVisitorCard) {
    return (
      <VisitorCard
        type={type}
        fullName={fullName}
        companyName={companyName}
        contactPerson={contactPerson}
        profilePhoto={profilePhoto}
        checkInStartDate={dateRange?.startDate || checkInStartDate}
        checkInEndDate={dateRange?.endDate || checkInEndDate}
        checkOutDate={checkOutDate}
        setShowVisitorCard={setShowVisitorCard}
      />
    )
  }

  const handleDownloadPDF = async (url, name) => {
    // await api
    //   .post('settings/download', {
    //     url: 'https://assets.dev.dupont-dvms.com/privacy-policy/443%20Safety%20Instructions%20for%20Visitors%20(Flyer)%20Juli%202021-1.pdf'
    //   })
    //   .then(async (response) => {
    //     if (response.data) {
    //       console.log('response', response)
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err)
    //   })

    const res = await axios({
      url: url,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${name + '.pdf'}`)
      document.body.appendChild(link)
      link.click()
    })
  }

  const isToday = (d1, d2) => {
    return d1?.toDateString() == d2?.toDateString()
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values, formikHelpers) => {
        if (status !== 'check_out' && status !== 'pre_check_in') {
          handleUpdateStatus(values)
          if (status === 'draft_check_in') {
            setShowVisitorCard(true)
          }
        }
      }}
      validationSchema={Yup.object().shape({
        cardHandedOut: Yup.bool(),
        cardNumber: Yup.string().when('cardHandedOut', {
          is: (cardHandedOut) => cardHandedOut == true,
          then: () =>
            Yup.string()
              .required(t('card issued required'))
              .matches(
                /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
                t('card number validation')
              ),
          otherwise: (schema) => schema.notRequired(),
        }),
      })}
    >
      {({
        errors,
        values,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
      }) => {
        return (
          <form onSubmit={handleSubmit} className='items-center '>
            <Card
              sx={{
                maxWidth: '100%',
                borderRadius: '20px',
                width: '750px',
                // minHeight: '400px',
                maxHeight: '95vh',
                overflowY: 'auto',
                marginTop: '30px',
              }}
            >
              <div className='card-header text-left ml-3 flex justify-between px-6 pt-1'>
                <h4
                  className={`text-textGray text-2xl font-diodrumSemibold inline-block mt-2 capitalize-first-letter ${i18n.language === 'en'
                    ? 'capitalize'
                    : 'capitalize-first-letter'
                    }`}
                >
                  {getTitle(status)}
                </h4>
                <button
                  className='text-textGray text-2xl font-diodrumSemibold'
                  onClick={() => setCardOpen(false)}
                >
                  <img width={15} src='/images/icons/close-icon.png' />
                </button>
              </div>
              <CardContent sx={{ padding: 0, textAlign: 'left' }}>
                <div className='flex justify-between text-xs leading-6 px-9 py-1'>
                  <div>
                    <div className='font-diodrumSemibold'>
                      <span>{t('type')}: </span>
                      <span>{getType(type, t)}</span>
                    </div>
                    <div>
                      <span>Name: </span>{' '}
                      <span>{showVisitorData(fullName, t, 'first-name')}</span>
                    </div>
                    <div>
                      <span className='capitalize'>{t('last name')}: </span>{' '}
                      <span>{showVisitorData(fullName, t, 'last-name')}</span>
                    </div>
                    <div>
                      <span>{t('company')}: </span>{' '}
                      <span>{showVisitorData(companyName, t)}</span>
                    </div>
                    <div>
                      <span>{t('email')}: </span>{' '}
                      <span>{showVisitorData(email, t)}</span>
                    </div>
                    <div>
                      <span>{t('contact person')}: </span>{' '}
                      <span>{showVisitorData(contactPerson, t)}</span>
                    </div>
                    <div>
                      <span>{t('plate number')}: </span>{' '}
                      <span>{showVisitorData(plateNumber, t)}</span>
                    </div>
                    <div>
                      <span>{t('phone')}: </span>{' '}
                      <span>{showVisitorData(telNumber, t)}</span>
                    </div>
                    {type !== 'employee' && (
                      <div>
                        <span>
                          {t('security document')}:{' '}
                          {type === 'Anonymised'
                            ? t('anonymised')
                            : pdfFiles && (
                              <a
                                className='hover:underline hover:text-textDarkBlue'
                                target='_blank'
                                href={getSafetyDocs()?.link}
                              >
                                {getSafetyDocs()?.name}
                              </a>
                            )}
                        </span>
                        {/* <a
                            className='cursor-pointer'
                            target='_blank'
                            onClick={() =>
                              handleDownloadPDF(
                                getSafetyDocs()?.link,
                                getSafetyDocs()?.name
                              )
                            }
                          >
                            <span className='mr-2 ml-1 hover:underline'>
                              {t('download')}
                            </span>
                            <CloudUploadIcon sx={{ color: '#d5dbea' }} />
                          </a> */}
                      </div>
                    )}
                    {table !== 'checkout' &&
                      ['check_in', 'pre_check_in'].includes(status) && (
                        <div className='font-diodrumSemibold'>
                          <span>Status: </span>
                          <span className={`text-green inline-block`}>
                            <span className='capitalize'>
                              {getStatusLabelOnModal(status, t)}
                            </span>
                            {' ' +
                              t('on') +
                              ' ' +
                              formatDate(checkInStartDate) +
                              ' ' +
                              t('at') +
                              ' ' +
                              formatTime(checkInStartDate)}
                            {checkInEndDate && (
                              <>
                                {' - '}
                                {formatDate(checkInEndDate)}{' '}
                                {' ' + t('at') + ' '}
                                {formatTime(checkInEndDate)}
                              </>
                            )}
                          </span>
                        </div>
                      )}
                    {table !== 'checkout' && checkOutDate && (
                      <div className='font-diodrumSemibold mb-3'>
                        <span className='text-black'>
                          {t('check in/out period')}:{' '}
                          {formatDate(checkInStartDate)}
                          {' ' + t('at') + ' '}
                          {formatTime(checkInStartDate)}
                          {' /'} {formatDate(checkOutDate)}{' '}
                          {' ' + t('at') + ' '} {formatTime(checkOutDate)}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className='flex flex-col'>
                    <div className='flex justify-end'>
                      {profilePhoto ? (
                        <img
                          src={profilePhoto}
                          style={{
                            width: 65,
                            height: 65,
                            borderRadius: '20px',
                            marginX: '15px',
                          }}
                        />
                      ) : (
                        <Avatar
                          sx={{
                            bgcolor: '#d7d7d7',
                            width: 65,
                            height: 65,
                            borderRadius: '20px',
                            marginX: '15px',
                          }}
                          aria-label='recipe'
                        />
                      )}
                    </div>
                  </div>
                </div>
                {dataType !== 'read-only' && (
                  <>
                    <div className=' text-sm leading-6 px-9 border border-lightGray border-t-2 border-x-0 border-b-0 pt-3'>
                      {checkOutDate && (
                        <div className='font-diodrumSemibold mb-3'>
                          <span className='text-black'>
                            {t('check in/out period')}:{' '}
                            {formatDate(checkInStartDate)}
                            {' ' + t('at') + ' '}
                            {formatTime(checkInStartDate)}
                            {' /'} {formatDate(checkOutDate)}{' '}
                            {' ' + t('at') + ' '} {formatTime(checkOutDate)}
                          </span>
                        </div>
                      )}

                      {(status === 'draft_check_in' ||
                        (status === 'check_in' && initialValues.cardNumber) ||
                        (status === 'pre_check_in' &&
                          initialValues.cardNumber) ||
                        (status === 'check_out' &&
                          initialValues.cardNumber)) && (
                        <div className='flex justify-between'>
                          <div>
                            <div className='-ml-1 mb-2'>
                              <Checkbox
                                name='cardHandedOut'
                                checked={
                                  values.cardHandedOut ||
                                  Boolean(initialValues.cardNumber)
                                }
                                disabled={Boolean(initialValues.cardNumber)}
                                onChange={(e) => {
                                  handleChange(e)
                                  if (!e.target.checked) {
                                    handleChange({
                                      target: { name: 'cardNumber', value: '' },
                                    })
                                  }
                                }}
                                sx={{ padding: 0, marginRight: '4px' }}
                              />
                              <span>{t('visitor card issued')}? </span>
                            </div>

                              <div className='w-full'>
                                <input
                                  className='border border-1 border-gray p-1 w-52'
                                  style={{
                                    backgroundColor: initialValues.cardNumber
                                      ? '#D1D5DB'
                                      : '#FFFFFF',
                                  }}
                                  placeholder={t('enter card number')}
                                  name='cardNumber'
                                  value={values.cardNumber}
                                  disabled={
                                    !values.cardHandedOut ||
                                    Boolean(initialValues.cardNumber)
                                  }
                                  onChange={(e) => {
                                    handleChange({
                                      target: {
                                        name: 'cardNumber',
                                        value: e.target.value.replace(
                                          /[^0-9]/g,
                                          ''
                                        ),
                                      },
                                    })
                                  }}
                                />
                                {errors?.cardNumber && (
                                  <span className='block'>
                                    <p className='text-[11px] text-red ml-1 text-errorBorder '>
                                      {errors.cardNumber}
                                    </p>
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        )}

                      <div className='-ml-1 mb-2 mt-4'>
                        <Checkbox
                          name='disableSecurityCheck'
                          checked={disableSecurityCheck}
                          disabled
                          sx={{ padding: 0, marginRight: '4px' }}
                        />
                        <span>{t('disable security check')} </span>
                      </div>

                      <div
                        onClick={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                          QRCode.toDataURL(
                            JSON.stringify({ visitSectionId: visitSectionId }),
                            { margin: 0, width: 1000 },
                            function (err, url) {
                              const link = document.createElement('a')
                              link.href = url
                              link.download = fullName
                              link.click()
                            }
                          )
                        }}
                        className='mt-4 cursor-pointer flex flex-row items-center font-semibold underline gap-1'
                      >
                        <QrCode />
                        {t('download QR code')}
                      </div>
                    </div>

                    <div className='flex justify-between pb-1'>
                      {status === 'draft_check_in' ? (
                        <div className='text-sm px-9 mt-4'>
                          <CalendarIcon className='inline-flex m-auto' />
                          <span className='mx-2 font-diodrumSemibold'>
                            {t('create a long-term check-in')}{' '}
                          </span>
                          {!isCalendarOpen && (
                            <button
                              onClick={handleCalendar}
                              className={`${!isCalendarOpen
                                ? 'bg-buttonGray px-6 text-white text-xs'
                                : 'text-textGray text-xl font-diodrumSemibold'
                                } py-1 rounded-md `}
                            >
                              <span>{t('apply')}</span>
                            </button>
                          )}
                          {isCalendarOpen ? (
                            <div className='mt-3'>
                              <DatePicker
                                type='date-range-picker'
                                dateRange={dateRange}
                                setDateRange={setDateRange}
                                setOpen={handleCalendar}
                                open={isCalendarOpen}
                                disablePrevievDates={true}
                              />
                            </div>
                          ) : (
                            dateRange &&
                            !isToday(
                              dateRange?.startDate,
                              dateRange?.endDate
                            ) && (
                              <p className='ml-6'>
                                {formatDate(dateRange?.startDate)} -{' '}
                                {formatDate(dateRange?.endDate)}
                              </p>
                            )
                          )}
                        </div>
                      ) : (
                        <div />
                      )}
                      <div className='pr-4 flex flex-col justify-end'>
                        <div className='flex justify-end'>
                          <button
                            type='submit'
                            className={`${getButtonStyle(status)?.bgColor
                              } p-2 rounded-md text-white w-auto h-10 mr-7 ${i18n.language === 'en'
                                ? 'capitalize'
                                : 'capitalize-first-letter'
                              }`}
                          >
                            {getButtonStyle(status)?.label}
                          </button>
                        </div>
                        <div className='flex items-center justify-end mt-5 h-6'>
                          {deleteDialogOpen && (
                            <div className='flex gap-x-1 items-center mr-2'>
                              <CheckCircleRounded
                                className='flex p-0.5 cursor-pointer'
                                fontSize='medium'
                                color='success'
                                sx={{
                                  padding: '2px',
                                }}
                                onClick={() => deleteVisitor()}
                              />

                              <CancelIcon
                                className='cursor-pointer text-red p-0.5'
                                fontSize='medium'
                                onClick={() =>
                                  setDeleteDialogOpen(!deleteDialogOpen)
                                }
                              />

                              <p className='text-sm mb-0.5'>{t('delete')}</p>
                            </div>
                          )}
                          <div
                            className='flex items-center gap-x-0.5 mr-1 cursor-pointer'
                            onClick={() =>
                              setDeleteDialogOpen(!deleteDialogOpen)
                            }
                          >
                            <img
                              className='w-4'
                              alt='bin'
                              src='/images/icons/bin.png'
                              width={16}
                              height={10}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </CardContent>
            </Card>
          </form>
        )
      }}
    </Formik>
  )
}
