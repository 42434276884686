import React, { useEffect, useState } from 'react'
import { ExpandMore } from '@mui/icons-material'
import Checkbox from '@mui/material/Checkbox'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import store from '../store'
import {
  setTestPassed,
  setTestNotPassed,
  setApplyFilter
} from '../store/slices/checkins-slice'

export default function FilterMenu({
  width,
  position,
  className,
  handleClose
}) {
  const [expandFilter, setExpandFilter] = useState(false)

  const { t } = useTranslation()
  const { testPassed, testNotPassed } = useSelector((state) => state.checkins)

  const handleClickFilter = () => {
    setExpandFilter(!expandFilter)
  }

  const handleTestPassed = () => {
    store.dispatch(setTestPassed(!testPassed))
  }
  const handleTestNotPassed = () => {
    store.dispatch(setTestNotPassed(!testNotPassed))
  }

  const handleApplyFilter = () => {
    store.dispatch(setApplyFilter(true))
    handleClose()
  }

  useEffect(() => {
    store.dispatch(setApplyFilter(false))
  })
  return (
    <div
      className={`bg-white inline-block w-full h-full text-left text-sm rounded-lg pb-6`}
    >
      <div className='w-full p-3'>
        <div className='bg-lightGray block mr-7 ml-[1rem] border border-[1px] border-[#b9bbbf] hover:border-[#070707] rounded cursor-pointer w-auto text-[#c6cfe4]'>
          <button
            className='flex justify-between w-full p-2'
            onClick={handleClickFilter}
          >
            <p className='ml-[5px]'>{t('filter by passed test')}</p>
            <ExpandMore sx={{ color: '#c6cfe4', marginRight: '10px' }} />
          </button>
          {expandFilter && (
            <ul className='border-t-[1px] border-[#b9bbbf] p-3'>
              <li className='flex py-1'>
                <label className='basis-2/3 font-semibold'>{t('test passed')}</label>
                <div className='mb-2'>
                  <Checkbox
                    name='testPassed'
                    checked={testPassed}
                    onChange={handleTestPassed}
                    sx={{ padding: 0, marginRight: '4px', color: '#c6cfe4' }}
                  />
                </div>
              </li>
              <li className='flex py-1'>
                <label className='basis-2/3 font-semibold'>{t('test not passed')}</label>
                <div className='mb-2'>
                  <Checkbox
                    name='test-not-passed'
                    checked={testNotPassed}
                    onChange={handleTestNotPassed}
                    sx={{ padding: 0, marginRight: '4px', color: '#c6cfe4' }}
                  />
                </div>
              </li>
            </ul>
          )}
        </div>
        {expandFilter && (
          <button
            className='text-sm text-white bg-green px-4 py-2 rounded-md ml-4 mt-5'
            onClick={handleApplyFilter}
          >
            {t('apply filter')}
          </button>
        )}
      </div>
    </div>
  )
}
