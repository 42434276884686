import React, { useState, useEffect } from 'react'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { Avatar } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { CheckCircleRounded } from '@mui/icons-material'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckInsCard from '../cards/check-ins'
import Popup from '../popup'
import usePopupVisible from '../../hooks/usePopupVisible'
import api from '../../api'
import {
  formatDate,
  formatTime,
  getStatusLabel,
  getType,
  showVisitorData
} from '../../helpers'
import { useTranslation } from 'react-i18next'
import { QrCode } from '@mui/icons-material'
import * as QRCode from 'qrcode';


function descendingComparator(a, b, orderBy) {
  if (b[orderBy]?.toLowerCase() < a[orderBy]?.toLowerCase()) {
    return -1
  }
  if (b[orderBy]?.toLowerCase() > a[orderBy]?.toLowerCase()) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index])
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis?.map((el) => el[0])
}

export default function Test({
  rows,
  order,
  orderBy,
  page,
  rowsPerPage,
  selected,
  hasSort,
  hasFilter,
  setSelected,
  searchQuery,
  dataType,
  getData,
  table
}) {
  const [isCardOpen, setCardOpen] = useState(false)
  const [currentCheckin, setCurrentCheckin] = useState(null)
  const [hover, setHover] = useState()
  const [rowToViewSettings, setRowToViewSetting] = useState(null)
  const [currentRowToDelete, setCurrentRowToDelete] = useState(null)
  const [isDeletePopupOpen, setDeletePopupOpen] = useState(false)

  const handleToggleDeletePopup = (row) => {
    setDeletePopupOpen(!isDeletePopupOpen)
    setCurrentRowToDelete(row)
    setRowToViewSetting(null)
  }

  const { t, i18n } = useTranslation()

  const { ref, isPopupVisible, setIsPopupVisible } = usePopupVisible(false)

  const getStatus = (status, hover) => {
    if (status === 'draft_check_in' && dataType !== 'read-only')
      return { bgColor: 'bg-green', label: t('confirm') }
    if (status === 'check_out')
      return { bgColor: 'bg-darkestBlue', label: t('checked out') }
    if (status === 'pre_check_in') {
      return { bgColor: 'bg-buttonGray', label: t('pre check in') }
    }
    if (status === 'check_in' && hover && dataType !== 'read-only') {
      return { bgColor: 'bg-red', label: t('check out') }
    }
    if (status === 'check_in' && dataType === 'read-only' && table === 'sos') {
      return { bgColor: 'bg-buttonGray', label: t('checked in') }
    }
    if (
      status === 'draft_check_in' &&
      dataType === 'read-only' &&
      table === 'history'
    ) {
      return { bgColor: 'bg-buttonGray', label: t('pending') }
    }
    if (
      status === 'draft_check_in' &&
      table === 'sos' &&
      dataType === 'read-only'
    ) {
      return { bgColor: 'bg-buttonGray', label: t('confirm') }
    }

    return { bgColor: 'bg-buttonGray', label: t('checked in') }
  }

  const checkinTime = (date) => {
    const timeToReturn = formatTime(date)

    if (date === null) {
      return null
    } else {
      return timeToReturn
    }
  }
  const checkiInDate = (date) => {
    const dataToReturn = formatDate(date)
    if (date === null) {
      return null
    } else {
      return dataToReturn
    }
  }

  const isSelected = (fullName) => selected?.indexOf(fullName) !== -1

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows?.length) : 0

  const handleClick = (event, row) => {
    setCardOpen(true)
    setIsPopupVisible(true)
    setCurrentCheckin(row)
  }

  const handleHover = (row) => {
    setHover(row)
  }

  const renderingRows = hasSort
    ? stableSort(rows, getComparator(order, orderBy))
    : rows

  const deleteVisitor = async () => {
    await api
      .delete(`visitor-section/${currentRowToDelete.visitSectionId}`)
      .then(async (response) => {
        if (response.data) {
          setCurrentRowToDelete(null)
          getData(page)
        }
      })
      .catch((err) => console.log('err', err))
  }

  useEffect(() => {
    setIsPopupVisible(isDeletePopupOpen)
  }, [isDeletePopupOpen])

  useEffect(() => {
    !isPopupVisible && setDeletePopupOpen(false)
  }, [isPopupVisible])

  return (
    <TableBody>
      {renderingRows?.map((row, index) => {
        const isItemSelected = isSelected(row.fullName)
        const labelId = `enhanced-table-checkbox-${index}`
        return (
          <TableRow
            hover
            role='checkbox'
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={index}
            onClick={(event) => {
              handleClick(event, row)
              setCurrentRowToDelete(null)
              setRowToViewSetting(null)
            }}
            sx={{ cursor: 'pointer' }}
          >
            <TableCell
              padding='checkbox'
              sx={{ display: 'block', marginY: '10px', marginX: '15px' }}
            >
              {(row.profilePhoto && (
                <img
                  id='profile-image-table'
                  width={45}
                  style={{
                    height: '45px',
                    borderRadius: '20px',
                    objectFit: 'cover'
                  }}
                  src={row.profilePhoto}
                  alt=''
                />
              )) || (
                <Avatar
                  sx={{
                    bgcolor: '#d7d7d7',
                    width: 45,
                    height: 45,
                    borderRadius: '20px'
                  }}
                  aria-label='recipe'
                />
              )}
            </TableCell>
            <TableCell component='th' id={labelId} scope='row' padding='none'>
              <p className='text-sm text-textDarkBlue font-diodrumMedium ml-3'>
                {showVisitorData(row.fullName, t) +
                  ' ' +
                  (i18n.language === 'de' && row.status === 'draft_check_in'
                    ? ''
                    : t('is') + ' ')}
                {getStatusLabel(row.status, t)}
              </p>
              <div className='inline-block capitalize-first-letter'>
                <span
                  className={`inline-block capitalize-first-letter text-xs font-diodrumMedium ${
                    ['check_in'].includes(row.status)
                      ? 'text-green'
                      : row.status === 'check_out'
                      ? 'text-red'
                      : 'text-textGray'
                  } ${
                    (row.status === 'check_out' ||
                      row.status === 'check_in' ||
                      row.status === 'draft_check_in') &&
                    'mr-4'
                  } ml-3`}
                >
                  {['check_in'].includes(row.status)
                    ? t('present')
                    : ['pre_check_in'].includes(row.status)
                    ? ''
                    : row.status === 'check_out'
                    ? t('Checked out')
                    : t('Waiting for approval')}
                </span>
                <span className='text-xs text-textGray'>
                  {row.checkInStartDate !== null && row.checkOutDate === null
                    ? checkiInDate(row.checkInStartDate) + ' ' + t('at') + ' '
                    : row.checkInStartDate !== null && row.checkOutDate !== null
                    ? checkiInDate(row.checkOutDate) + ' ' + t('at') + ' '
                    : ''}
                </span>
                <span className='text-xs text-textGray'>
                  {row.checkInStartDate !== null && row.checkOutDate === null
                    ? checkinTime(row.checkInStartDate) + ' ' + t('clock') + ' '
                    : row.checkInStartDate !== null && row.checkOutDate !== null
                    ? checkinTime(row.checkOutDate) + ' ' + t('clock') + ' '
                    : ''}
                </span>
              </div>
            </TableCell>
            {table !== 'sos' && (
              <>
                {' '}
                <TableCell align='center'>
                  <p className='textBlack text-sm font-diodrumMedium'>
                    {getType(row.type, t)}
                  </p>
                </TableCell>
                <TableCell align='center'>
                  <p
                    className={`${
                      table === 'sos' ? 'w-28' : ''
                    } textBlack text-sm font-diodrumMedium`}
                  >
                    <span>
                      {row.checkInStartDate !== null &&
                      row.checkOutDate === null
                        ? checkinTime(row.checkInStartDate) +
                          ' ' +
                          t('clock') +
                          ' '
                        : row.checkInStartDate !== null &&
                          row.checkOutDate !== null
                        ? checkinTime(row.checkInStartDate) +
                          ' ' +
                          t('clock') +
                          ' - ' +
                          checkinTime(row.checkOutDate) +
                          ' ' +
                          t('clock') +
                          ' '
                        : ''}
                    </span>
                  </p>
                </TableCell>
                <TableCell align='center' padding='none'>
                  <button
                    onMouseEnter={() => handleHover(row)}
                    onMouseLeave={() => {
                      handleHover(null)
                    }}
                    className={`${
                      getStatus(
                        row.status,
                        row.visitSectionId === hover?.visitSectionId
                      )?.bgColor
                    } relative rounded-md text-white w-full xl:w-32 h-7`}
                  >
                    <span
                      className={`md:absolute inset-0 top-[2px] m-auto ${
                        i18n.language === 'en'
                          ? 'capitalize'
                          : 'capitalize-first-letter'
                      }`}
                    >
                      {
                        getStatus(
                          row.status,
                          row.visitSectionId === hover?.visitSectionId
                        )?.label
                      }{' '}
                    </span>
                  </button>
                </TableCell>{' '}
              </>
            )}
            {table === 'check-ins' && (
              <TableCell align='center' onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                QRCode.toDataURL(JSON.stringify({ visitSectionId: row.visitSectionId }), { margin: 0 , width: 1000},  function (err, url) {
                  const link = document.createElement("a");
                  link.href = url;
                  link.download = row.fullName;
                  link.click();
                })
              }}>
                  <QrCode sx={{ color: '#9191af' }} />
              </TableCell>
            )}
            {table === 'check-ins' && (
              <TableCell >
                <span className='text-red w-full flex justify-end text-[13px] font-semibold'>
                  {row.type !== 'worker' &&
                  row.correctlyAnswered === false &&
                  dataType !== 'read-only'
                    ? t('test not passed')
                    : ''}
                </span>
              </TableCell>
            )}
            {table === 'check-ins' && (
              <TableCell
                align='left'
                padding='none'
                className='w-10 relative text-left'
                onClick={(event) => {
                  event.stopPropagation()
                  setRowToViewSetting((prev) => (!!prev ? null : row))
                }}
              >
                <MoreVertIcon
                  className='cursor-pointer text-textGray p-0.5 z-30'
                  fontSize='medium'
                />
                {rowToViewSettings?.visitSectionId === row.visitSectionId && (
                  <div className='absolute bg-white shadow-4xl right-0 w-24 p-2 rounded-md'>
                    <div
                      className='flex gap-x-1'
                      onClick={() => handleToggleDeletePopup(row)}
                    >
                      <img
                        className='w-4'
                        alt='bin'
                        src='/images/icons/bin.png'
                        width={16}
                        height={10}
                      />
                      <p>{t('delete')}</p>
                    </div>
                  </div>
                )}
              </TableCell>
            )}
            {table === 'sos' ? (
              <TableCell align='center' width={200}>
                <span className='text-base'>{row?.contactPerson}</span>
              </TableCell>
            ) : (
              table !== 'check-ins' && <TableCell />
            )}
          </TableRow>
        )
      })}
      <Popup
        open={isPopupVisible}
        position='fixed left-0 right-0 bottom-0 top-0'
      >
        <div
          ref={ref}
          className='h-auto w-auto flex flex-col justify-center items-center'
        >
          {isDeletePopupOpen ? (
            <DeleteCheckin
              row={currentRowToDelete}
              deleteVisitor={deleteVisitor}
              handleToggleDeletePopup={handleToggleDeletePopup}
            />
          ) : (
            currentCheckin && (
              <CheckInsCard
                dataType={dataType}
                currentCheckin={currentCheckin}
                setCardOpen={setIsPopupVisible}
                getData={getData}
                page={page}
                rows={rows}
                table={table}
              />
            )
          )}
        </div>
      </Popup>
    </TableBody>
  )
}

const DeleteCheckin = ({ row, deleteVisitor, handleToggleDeletePopup }) => {
  const { t } = useTranslation()

  return (
    <div className='bg-white rounded-lg w-96'>
      <div className='my-5 mx-6'>
        <div className=' flex justify-center items-center text-center'>
          <p className='w-4/5 font-diodrumMedium text-black mb-5'>
            {t('delete-checkin')}
          </p>
        </div>

        <div className='flex justify-evenly mx-20 items-center mb-5'>
          <CheckCircleRounded
            className='flex p-0.5 cursor-pointer'
            fontSize='large'
            color='success'
            sx={{
              padding: '2px'
            }}
            onClick={() => {
              deleteVisitor(row.visitSectionId)
              handleToggleDeletePopup(null)
            }}
          />

          <CancelIcon
            className='cursor-pointer text-red p-0.5'
            fontSize='large'
            onClick={(event) => {
              event.stopPropagation()
              handleToggleDeletePopup(null)
            }}
          />
        </div>
      </div>
    </div>
  )
}
