import React, { useState } from 'react'
import { Field } from 'formik'

const UploadField = ({
  uppername,
  touched,
  error,
  name,
  type,
  placeholder,
  onChange,
  onClick,
  value,
  helperText,
  className,
  width,
  errorMessage,
  accept='*'
}) => {
  const [isHovered, setIsHovered] = useState(false)

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  const isPlaceholderLong = placeholder?.length > 35

  return (
    <div
      className='flex flex-col w-full'
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleMouseLeave}
    >
      <div
        className={`${className} ${
          touched || error ? 'border border-errorBorder ' : ''
        }`}
      >
        <div className='absolute top-1 left-3 justify-start text-[12px] font-diodrumSemibold text-black capitalize'>
          <p>{uppername}</p>
        </div>
        <label className='flex flex-row justify-between file-upload'>
          <Field
            id='file-upload'
            className='w-full border-none outline-none mt-3 flex justify-center items-start text-left'
            type={type}
            name={name}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            onClick={onClick}
            error={error}
            helperText={helperText}
            accept={accept}
          />
          <p className='mt-4'>
            {(value && value?.split('\\')[2]) ||
              placeholder.substr(0, 35) + `${isPlaceholderLong ? '...' : ''}`}
          </p>

          {isHovered && placeholder && isPlaceholderLong && (
            <span className='absolute bg-buttonGray text-black text-xs px-2 py-1 rounded bottom-7 z-10'>
              {placeholder}
              <div className='triangle'></div>
            </span>
          )}
          <span className='pr-5 my-auto'>
            <img
              alt=''
              src='/images/icons/donwload-button.png'
              width='18'
              className='mx-auto mt-5'
            />
          </span>
        </label>
      </div>
      <div className='relative h-1 flex justify-start text-start items-start '>
        {touched || error ? (
          <p className='text-[11px] text-red ml-1  text-errorBorder mt-[-10px]   '>
            {errorMessage}
          </p>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}

export default UploadField
