import { Field } from 'formik'
import { useTranslation } from 'react-i18next'

const TextField = ({
	uppername,
	touched,
	error,
	name,
	type,
	placeholder,
	onChange,
	onClick,
	value,
	disabled,
	tooltip,
	helperText,
	className,
	errorMessage,
	required
}) => {
	const { t } = useTranslation()

	return (
		<div className='flex flex-col mb-5'>
			<div
				className={`${className} ${
					touched || error ? 'border border-errorBorder ' : ''
				}`}
			>
				<div className='absolute top-1 left-3 justify-start text-[12px] text-gray-300 font-diodrumSemibold text-black'>
					<p>{uppername} {required ? <span className='text-red text-sm'>*</span> : ''}</p>
				</div>

				<Field
					title={tooltip}
					disabled={disabled}
					className={`placeholder w-full border-none outline-none mt-3 flex justify-start items-start text-left text-[15px] pr-3 placeholder:h-full placeholder-textGray ${
						value === placeholder ? 'text-textGray' : 'text-zinc-400'
					}`}
					type={type}
					name={name}
					placeholder={placeholder}
					value={value}
					onChange={onChange}
					onClick={onClick}
					error={error}
					helperText={helperText}
				/>
			</div>

			<div className='relative h-1 flex justify-start text-start items-start '>
				{touched || error ? (
					<p className='text-[11px] text-red ml-1  text-errorBorder mt-[1px] normal-case'>
						{t(errorMessage)}
					</p>
				) : (
					''
				)}
			</div>
		</div>
	)
}

export default TextField
