import QRCode from 'react-qr-code'

import { pipe } from 'fp-ts/lib/function'
import * as IOO from 'fp-ts/IOOption'
import { useTranslation } from 'react-i18next'

const ID = 'qr-code'
const IMAGE_SIZE = 1000

function getSvgFileUrl() {
  return pipe(
    IOO.fromNullable(document.getElementById(ID)),
    IOO.map((node) => new XMLSerializer().serializeToString(node)),
    IOO.map((svg) => new Blob([svg], { type: 'image/svg+xml' })),
    IOO.chainIOK((blob) => () => URL.createObjectURL(blob))
  )
}

export default function QRCodeGenerator({ value, fileName }) {
  const { t } = useTranslation()
  const handleDownloadPng = () => {
    pipe(
      getSvgFileUrl(),
      IOO.chainIOK((url) => () => {
        const canvas = document.createElement('canvas')

        canvas.width = IMAGE_SIZE
        canvas.height = IMAGE_SIZE

        const ctx = canvas.getContext('2d')

        const img = new Image()

        img.onload = () => {
          ctx?.drawImage(img, 0, 0, IMAGE_SIZE, IMAGE_SIZE)

          const pngFile = canvas.toDataURL('image/png')

          const downloadLink = document.createElement('a')
          downloadLink.download = fileName
          downloadLink.href = `${pngFile}`
          downloadLink.click()

          URL.revokeObjectURL(url)
        }

        img.src = url
      })
    )()
  }

  const handleDownloadSvg = () => {
    pipe(
      getSvgFileUrl(),
      IOO.chainIOK((url) => () => {
        const downloadLink = document.createElement('a')

        downloadLink.download = 'QRCode'
        downloadLink.href = url
        downloadLink.click()

        URL.revokeObjectURL(url)
      })
    )()
  }

  return (
    <div className='QRCode'>
      <QRCode id={ID} value={value} size={128} />
      <div className='flex justify-start py-3 mb-5 text-left items-left '>
        <button
          onClick={handleDownloadPng}
          className='flex justify-center items-center'
        >
          <p className='text-sm text-black  cursor-pointer hover:underline'>
            {t('download QR code')}
          </p>
          <a className='flex justify-center items-center pl-2'>
            <img
              src='/images/icons/donwload-button.png'
              alt='image'
              loading='lazy'
              className='w-4 h-auto -mb-1.5'
            />
          </a>
        </button>
      </div>
    </div>
  )
}
