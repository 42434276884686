import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router'
import { Trans, useTranslation } from 'react-i18next'
import QRCodeGenerator from '../components/QR-code'
import UnAuthLayout from '../layout/unAuthLayout'
import { state } from '../hooks/state'
import { getAuthedUser, parseJwt } from '../helpers/auth'
import Cookie from '../components/cookie'
const QRCodeContent = () => {
  const { newVisitor } = state

  const { t, i18n } = useTranslation()

  const privacyPolicyUrl =
    i18n.language === 'en'
      ? 'https://www.dupont.com/privacy.html'
      : 'https://www.dupont.de/privacy.html'

  let QRCodeValue = ''
  let fileName = 'QRCode'
  if (newVisitor) {
    const { visitSectionId, fullName } = newVisitor
    QRCodeValue = {
      visitSectionId,
    }
    fileName = fullName
  }

  const userToken = getAuthedUser()
  const user = userToken && parseJwt(userToken)

  return (
    <div className='flex justify-center h-full w-full bg-white relative'>
      <div className='flex flex-col my-0 w-4/5 justify-center'>
        <div className='grid grid-cols-2 mt-10'>
          <div className='flex mb-4 justify-start items-center rounded-lg '>
            <img
              src='/images/dupont-logo.png'
              alt='image'
              loading='lazy'
              className='lg:w-28 sm:h-8 h-auto'
            />
          </div>
        </div>
        <div className='flex flex-col p-3'>
          <div className='flex flex-col justify-start mt-10'>
            <div className='flex justify-start mb-5 '>
              <p className='text-sm text-zinc-400 uppercase'>
                {t('check in successful')}
              </p>
            </div>
            <div className='flex justify-start mb-5 text-left '>
              <p className='font-bold text-2xl text-black'>
                <Trans i18nKey='description.successfully-checkin' />
              </p>
            </div>
            <div className='flex flex-row'>
              <div className='flex justify-center py-3 mb-5 w-1/3 text-left items-center '>
                {/* <p className='text-sm text-zinc-300'>{t('card printed')}...</p> */}
              </div>
              <div className='w-2/3 flex justify-start pl-10'>
                <img
                  src='/images/icons/tick-green.png'
                  alt='image'
                  loading='lazy'
                  className='w-12 h-auto'
                />
              </div>
            </div>
            <QRCodeGenerator value={JSON.stringify(QRCodeValue)} fileName={fileName} />
          </div>
        </div>
        <div className='flex w-full lg:w-4/5 justify-center lg:my-0 lg:mx-auto min-w-0 break-words rounded-lg'>
          <div className='flex-auto lg:p-4 mt-11 lg:mt-0 '></div>
        </div>
        <div className='grid grid-cols-2'>
          <div className='grid text-left py-10 text-zinc-600 text-sm font-bold  mt-4'>
            <Link
              to={privacyPolicyUrl}
              state={'confirmationPage'}
              title={t('privacy tooltip')}
            >
              <a className='text-sm font-bold p-1 capitalize'>
                {t('privacy policy')}
              </a>
            </Link>
          </div>
          <div>
            <Link to={user?.role !== 'team_assistant' ? '/' : '/pre-checkin'}>
              <a className='flex ml-5 items-center'>
                <span className='grid grid-cols-2 gap-x-4 text-left items-center py-10'>
                  <p className='text-sm text-black '>
                    {t('back to visitor management')}
                  </p>

                  <img
                    src='/images/icons/next-page.png'
                    alt='image'
                    loading='lazy'
                    className='w-11 h-auto'
                  />
                </span>
              </a>
            </Link>
          </div>
        </div>
      </div>
      <Cookie
        position='absolute'
        width='w-full'
        marginBottom='bottom-0'
        boxShadow='shadow-none'
      />
    </div>
  )
}

const Confirmation = () => {
  const { newVisitor } = state

  const navigate = useNavigate()
  useEffect(() => {
    if (!newVisitor) navigate('/')
  }, [])

  return (
    <UnAuthLayout>
      <QRCodeContent />
    </UnAuthLayout>
  )
}
export default Confirmation
