import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import api from '../../api';
import QuestionnareCard from '../cards/questionnare';
import UploadField from './form-components/upload-field';
import SelectField from './form-components/select-field';
import RoundedComponent from '../cards/rounded';

const SettingsForm = () => {
  const [data, setData] = useState({});
  const { t, i18n } = useTranslation();
  const [visitorDocName, setVisitorDocName] = useState('');
  const [contractorDocName, setContractorDocName] = useState('');
  const [privacyPolicyName, setPrivacyPolicyName] = useState('');

  const options = [
    {
      label: t('question catalog for instruction document') + ' 1',
      value: 'question_catalog_1',
    },
    {
      label: t('question catalog for instruction document') + ' 2',
      value: 'question_catalog_2',
    },
  ];

  useEffect(() => {
    if (i18n.language === 'en') setVisitorDocName(data?.safetyNameVisitorEn);
    if (i18n.language === 'de') setVisitorDocName(data?.safetyNameVisitorDe);
    if (i18n.language === 'en')
      setContractorDocName(data?.safetyNameContractorEn);
    if (i18n.language === 'de')
      setContractorDocName(data?.safetyNameContractorDe);
    if (i18n.language === 'en') setPrivacyPolicyName(data?.privacyNameEn);
    if (i18n.language === 'de') setPrivacyPolicyName(data?.privacyNameDe);
  }, [data, i18n.language]);

  const getSettingsPdf = async () => {
    await api
      .get(`/settings/pdf-url`)
      .then(async (response) => {
        if (response.data) {
          setData(response.data);
        }
      })
      .catch((err) => err);
  };

  const getPrivacyPolicySignedUrl = (file, fileName) => {
    api
      .get(`/settings/privacy-policy/signed-url?language=${i18n.language}`)
      .then((response) => {
        if (response.data) {
          uploadToS3(
            response.data.signedUrl,
            file,
            response.data.key,
            fileName
          );
        }
      })
      .catch((err) => err);
  };

  const getSignedUrl = (file, fileName) => {
    api
      .get(`/settings/signed-url`)
      .then((response) => {
        if (response.data) {
          uploadToS3(
            response.data.signedUrl,
            file,
            response.data.key,
            fileName
          );
        }
      })
      .catch((err) => err);
  };

  const uploadToS3 = (url, file, key, fileName) => {
    const contentType = {
      headers: { 'Content-type': `${file.type}`, 'Cache-Control': 'no-cache' },
    };

    axios
      .put(url, file, contentType)
      .then((res) => {
        if (res.status === 200) {
          postSettingsPdf(key, fileName, file);
        }
      })
      .catch((err) => err);
  };

  const postSettingsPdf = (key, fileName, file) => {
    let values;
    if (fileName === 'privacyPolicy' && i18n.language === 'en') {
      values = {
        privacyPathEn: key,
        privacyNameEn: file?.name,
      };
    } else if (fileName === 'privacyPolicy' && i18n.language === 'de') {
      values = {
        privacyPathDe: key,
        privacyNameDe: file?.name,
      };
    } else if (fileName === 'document1' && i18n.language === 'en') {
      values = {
        safetyPathVisitorEn: key,
        safetyNameVisitorEn: file?.name,
      };
    } else if (fileName === 'document1' && i18n.language === 'de') {
      values = {
        safetyPathVisitorDe: key,
        safetyNameVisitorDe: file?.name,
      };
    } else if (fileName === 'document2' && i18n.language === 'en') {
      values = {
        safetyPathContractorEn: key,
        safetyNameContractorEn: file?.name,
      };
    } else if (fileName === 'document2' && i18n.language === 'de') {
      values = {
        safetyPathContractorDe: key,
        safetyNameContractorDe: file?.name,
      };
    } else {
      values = {};
    }
    api
      .post('/settings', values)
      .then(async (response) => {
        await getSettingsPdf();
      })
      .catch((err) => err);
  };

  useEffect(() => {
    getSettingsPdf();
  }, []);

  return (
    <Formik
      initialValues={{
        privacyPolicy: '',
        document1: '',
        document2: '',
        questionCatalog: 'question_catalog_1',
      }}
      onSubmit={(values, formikHelpers) => {}}
      validationSchema={Yup.object().shape({
        privacyPolicy: Yup.string(),
        document1: Yup.string(),
        document2: Yup.string(),
        questionCatalog: Yup.string(),
      })}
    >
      {({
        errors,
        values,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
      }) => {
        return (
          <form onSubmit={handleSubmit} className='items-center '>
            <div className='grid grid-cols-2 grid-flow-col gap-w-full xl:w-2/3'>
              <div className='col-span-2 gap-3'>
                <div className='p-8'>
                  <div className='flex flex-start mb-5'>
                    <RoundedComponent renderNumb='1' />
                    <p className='text-sm text-black ml-3 uppercase'>
                      {t('safety instructions')}
                    </p>
                  </div>
                  <div className='flex flex-row justify-center ml-8'>
                    <div className=' pr-2 w-full'>
                      <UploadField
                        accept='application/pdf;video/mp4;video/quicktime'
                        uppername={t('instruction document') + ' 1'}
                        placeholder={
                          visitorDocName ||
                          t('upload') + ' ' + t('instruction document') + ' 1'
                        }
                        type='file'
                        name='document1'
                        value={values.document1}
                        className='w-full flex flex-col relative justify-center h-14 bg-white border-slate-300 text-sm pl-3 text-zinc-400 shadow-lg rounded-md'
                        onChange={(event) => {
                          const file = event.target.files[0];
                          getSignedUrl(file, event.target.name);
                        }}
                        
                        error={touched.document1 && Boolean(errors.document1)}
                        helperText={touched.document1 && errors.document1}
                        errorMessage={errors.document1}
                      />
                    </div>
                    <div className='px-3 w-full'>
                      <UploadField
                        uppername={t('instruction document') + ' 2'}
                        placeholder={
                          contractorDocName ||
                          t('upload') + ' ' + t('instruction document') + ' 2'
                        }
                        type='file'
                        name='document2'
                        value={values.document2}
                        className='w-full flex flex-col relative justify-center h-14 bg-white border-slate-300 text-sm pl-3 text-zinc-400 shadow-lg rounded-md'
                        onChange={(event) => {
                          const file = event.target.files[0];
                          getSignedUrl(file, event.target.name);
                        }}
                        error={touched.document2 && Boolean(errors.document2)}
                        helperText={touched.document2 && errors.document2}
                        errorMessage={errors.document2}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='p-8 mt-7'>
              <div className='flex flex-start mb-3'>
                <RoundedComponent renderNumb='2' />
                <p className='text-sm text-black ml-3 uppercase'>
                  {t('catalog of questions')}
                </p>
              </div>
              <div className='grid grid-rows-1 ml-8'>
                <div className='grid grid-cols-4 w-full -mb-4'>
                  <SelectField
                    uppername={t('choose question catalog')}
                    name={'questionCatalog'}
                    placeholder={t('choose question catalog')}
                    value={values.questionCatalog}
                    className='w-full flex flex-col relative justify-center h-14 bg-white border-slate-300 text-sm text-zinc-400 shadow-lg rounded-md'
                    error={
                      touched.questionCatalog && Boolean(errors.questionCatalog)
                    }
                    helperText={
                      touched.questionCatalog && errors.questionCatalog
                    }
                    errorMessage={errors.questionCatalog}
                    options={options}
                  />
                </div>
              </div>
              <div className='ml-8'>
                <QuestionnareCard questionCatalog={values.questionCatalog} />
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};
export default SettingsForm;
